<template>
  <div class="login_cover">
    <div class="login-box">
      <div class="left-img">
        <img src="@/assets/images/Full-Logo-Gold.png" class="site-logo" />
        <div class="email-call-info">
          <ul>
            <li>
              <a href="mailto:mreis@srlife.net">
                <i class="fal fa-envelope"></i> mreis@srlife.net
              </a>
            </li>
            <li>
              <a href="tel:8777778808">
                <i class="fal fa-phone-alt"></i> 877.777.8808
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="login-form">
        <validation-observer ref="loginObserver" v-slot="{ handleSubmit }">
          <v-form
            class="login-column"
            @submit.prevent="handleSubmit(changePassword)"
            ref="loginForm"
          >
            <div class="login-form-fild" v-if="!isForgotPassword">
              <v-row>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="New Password"
                    :rules="{
                      required: true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    }"
                    vid="confirm"
                    mode="lazy"
                  >
                    <v-text-field
                      label="New Password"
                      v-model="password"
                      title="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      class="form-control"
                      placeholder="New Password"
                      required
                      outlined
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      :disabled="loading"
                      dark
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Confirm Password"
                    :rules="{
                      required: true,
                      confirmed: password,
                    }"
                  >
                    <v-text-field
                      hint="Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character."
                      label="Confirm Password"
                      v-model="confirmPassword"
                      class="form-control"
                      placeholder="Confirm Password"
                      required
                      outlined
                      dark
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-lock-check"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :disabled="loading"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
            &nbsp;

            <div class="text-center">
              <v-btn
                class="signin-btn"
                type="submit"
                color="primary"
                dark
                rounded
                :disabled="loading"
                :loading="loading"
              >
                Save
              </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthService from "../services/AuthService";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },

  data: () => ({
    password: "",
    token: "",
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
    showOldPassword: false,
    confirmPassword: "",
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  methods: {
    async changePassword() {
      try {
        const response = await AuthService.resetPassword({
          newPassword: this.password,
          confirmPassword: this.confirmPassword,
          resetCode: this.token,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          this.$router.push({ name: "login" });
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();

      this.password = "";
      this.confirmPassword = "";
    },
  },
};
</script>
