var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login_cover"},[_c('div',{staticClass:"login-box"},[_vm._m(0),_c('div',{staticClass:"login-form"},[_c('validation-observer',{ref:"loginObserver",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-form',{ref:"loginForm",staticClass:"login-column",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[(!_vm.isForgotPassword)?_c('div',{staticClass:"login-form-fild"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"New Password","rules":{
                      required: true,
                      min: 8,
                      regex:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                    },"vid":"confirm","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"label":"New Password","title":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","placeholder":"New Password","required":"","outlined":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showPassword ? 'text' : 'password',"disabled":_vm.loading,"dark":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":{
                      required: true,
                      confirmed: _vm.password,
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"form-control",attrs:{"hint":"Password should be of atleast 8 characters and requires atleast one : uppercase letter, lowercase letter, number, special character.","label":"Confirm Password","placeholder":"Confirm Password","required":"","outlined":"","dark":"","append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"hide-details":!errors.length,"error-messages":errors,"prepend-icon":"mdi-lock-check","type":_vm.showConfirmPassword ? 'text' : 'password',"disabled":_vm.loading},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1)],1)],1):_vm._e(),_vm._v("   "),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"signin-btn",attrs:{"type":"submit","color":"primary","dark":"","rounded":"","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(" Save ")])],1)])]}}])})],1)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-img"},[_c('img',{staticClass:"site-logo",attrs:{"src":require("@/assets/images/Full-Logo-Gold.png")}}),_c('div',{staticClass:"email-call-info"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"mailto:mreis@srlife.net"}},[_c('i',{staticClass:"fal fa-envelope"}),_vm._v(" mreis@srlife.net ")])]),_c('li',[_c('a',{attrs:{"href":"tel:8777778808"}},[_c('i',{staticClass:"fal fa-phone-alt"}),_vm._v(" 877.777.8808 ")])])])])])
}]

export { render, staticRenderFns }